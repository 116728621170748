import React from "react";

const JobExperiance = (props) => {
  return (
    <>
      <div className="col-md-4">
        <div className="service-box">
          <div className="service-ico">
            <span className="ico-circle">
              <i
                className={
                  props.isEducation
                    ? "bi bi-mortarboard-fill"
                    : "bi bi-person-workspace"
                }
              ></i>
            </span>
          </div>
          <div className="service-content">
            <h2 className="s-title">{props.company}</h2>
            <p style={{ fontWeight: "bold" }}>{props.date}</p>
            <p className="s-description text-center">{props.bio}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobExperiance;
