import React, { useContext } from "react";
import AppContext from "../../Store/AppContext";
import Skill from "./Skill";

const Skills = () => {
  const data = useContext(AppContext);

  const skills = data.SkillsData;

  return (
    <div className="skill-mf">
      <p className="title-s">Programming Languages/Frameworks</p>
      {skills &&
        skills.map((skill, i) => (
          <Skill key={i} skillName={skill.NameOfSkill} level={skill.Level} />
        ))}
    </div>
  );
};

export default Skills;
