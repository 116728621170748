import React from "react";

const AboutProfile = (props) => {
  return (
    <>
      <div className="col-sm-6 col-md-7">
        <div className="about-info">
          <p>
            <span className="title-s">Name: </span>{" "}
            <span>{props.about.Name}</span>
          </p>
          <p>
            <span className="title-s">Age: </span>{" "}
            <span>{props.about.Age}</span>
          </p>
          <p>
            <span className="title-s">Email: </span>{" "}
            <span>{props.about.Email}</span>
          </p>
          <p>
            <span className="title-s">Phone: </span>{" "}
            <span>{props.about.TelePhone}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutProfile;
