import React from "react";

const PortfolioItem = (props) => {
  return (
    <>
      <div className="col-md-4">
        <div className="work-box">
          <a
            href="assets/img/work-1.jpg"
            data-gallery="portfolioGallery"
            className="portfolio-lightbox"
          >
            <div className="work-img">
              <img
                src={`https://api.oluadesina.com/${props.thumb}`}
                alt=""
                className="img-fluid"
              />
            </div>
          </a>
          <div className="work-content">
            <div className="row">
              <div className="col-sm-8">
                <h2 className="w-title">
                  <a href={"https://" + props.Website}>{props.website}</a>
                </h2>
                <div className="w-more">
                  <span
                    className="w-date"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {props.bio}
                  </span>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="w-like"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioItem;
