//import "bootstrap/dist/css/bootstrap.css";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import "./App.css";

import { useEffect, useState } from "react";
import Header from "./Components/header/Header";

import Hero from "./Components/Hero";
import Main from "./Components/Main";
import Footer from "./Components/Footer/Footer";
import AppContext from "./Store/AppContext";

function App() {
  const [data, setData] = useState();

  useEffect(() => {
    fetch("https://api.oluadesina.com/Umbraco/api/react/GetBackOfficeData")
      .then((response) => response.json())
      .then((UmbracoData) => setData(UmbracoData));
  }, []);

  return (
    <>
      {data && (
        <AppContext.Provider value={data}>
          <Header />
          <Hero heroPageData={data.HeroPageData} skills={data.SkillsData} />
          <Main />
          <Footer />
          <a
            href="/#"
            className="back-to-top d-flex align-items-center justify-content-center"
          >
            <i className="bi bi-arrow-up-short"></i>
          </a>
        </AppContext.Provider>
      )}
    </>
  );
}

export default App;
