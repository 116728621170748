import React from "react";

const Skill = (props) => {
  return (
    <>
      <span>{props.skillName}</span>{" "}
      <span className="pull-right">{props.level.Maximum}</span>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${props.level}%` }}
          aria-valuenow="85"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </>
  );
};

export default Skill;
