import React, { useContext } from "react";
import AppContext from "../../Store/AppContext";
// import Typed from "react-typed";
import Typewriter from "typewriter-effect";

const Section = () => {
  const data = useContext(AppContext);

  const quotes = [...data.AboutPageData.Quotes];

  return (
    <>
      <div
        className="section-counter paralax-mf bg-image"
        style={{
          backgroundImage: `url(https://api.oluadesina.com/${data.HeroPageData.BackgroundImage})`,
        }}
      >
        <div className="overlay-mf"></div>
        <div className="container position-relative">
          <div style={{ textAlign: "center" }}>
            {/* <Typed
              strings={quotes}
              typeSpeed={80}
              backSpeed={80}
              style={{
                color: "#fff",
                fontWeight: 600,
                fontSize: "2.5rem",
                display: "inline-block",
              }}
            /> */}
            <div
              style={{
                color: "#fff",
                fontWeight: 600,
                fontSize: "2.5rem",
                display: "inline-block",
              }}
            >
              <Typewriter
                onInit={(typewriter) => {
                  quotes.map((q) => {
                    return typewriter
                      .pauseFor(1000)
                      .deleteAll()
                      .typeString(q)
                      .start();
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
