import React from "react";
import parse from "html-react-parser";

const AboutMeText = (props) => {
  return (
    <>
      <div className="col-md-6">
        <div className="about-me pt-4 pt-md-0">
          <div className="title-box-2">
            <h5 className="title-left">About Me</h5>
          </div>

          <p className="lead">{parse(props.bio)}</p>
        </div>
      </div>
    </>
  );
};

export default AboutMeText;
