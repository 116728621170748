import React, { useContext } from "react";
import AppContext from "../../Store/AppContext";
import JobExperiance from "./JobExperiance";

function Experiance(props) {
  const data = useContext(AppContext);

  const jobs = data.ExperienceData;

  return (
    <>
      <section id="services" className="services-mf pt-5 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Education & Experiance</h3>
                <p className="subtitle-a"></p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {jobs.map((job, i) => (
              <JobExperiance
                key={i}
                company={job.Company}
                isEducation={job.IsEducation}
                date={job.Dates}
                bio={job.JobBio}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Experiance;
