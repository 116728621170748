import React, { useContext } from "react";
import PortfolioItem from "./PortfolioItem";
import AppContext from "../../Store/AppContext";

const Portfolio = () => {
  const data = useContext(AppContext);

  const PortfolioItems = data.WebSiteData;

  return (
    <>
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Portfolio</h3>
                <p className="subtitle-a"></p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {PortfolioItems.map((p, i) => (
              <PortfolioItem
                key={i}
                website={p.Website}
                thumb={p.WebsiteThumb}
                bio={p.WebsiteInfo}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
