import React from "react";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Experiance from "./Experiance/Experiance";
import Portfolio from "./Portfolio/Portfolio";
import Section from "./Section/Section";

const Main = () => {
  return (
    <main id="main">
      <About />
      <Experiance />
      <Section />
      <Portfolio />
      <Contact />
    </main>
  );
};

export default Main;
