import React, { useContext } from "react";
import AppContext from "../../Store/AppContext";
import Skills from "../Skills/Skills";
import AboutMeText from "./AboutMeText";
import AboutProfile from "./AboutProfile";

const About = () => {
  const data = useContext(AppContext);

  const aboutData = data.AboutPageData;

  return (
    <section id="about" className="about-mf sect-pt4 route">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="box-shadow-full">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-sm-6 col-md-5">
                      <div className="about-img">
                        <img
                          src={`https://api.oluadesina.com/${aboutData.Profilepic}`}
                          className="img-fluid rounded b-shadow-a"
                          alt=""
                        />
                      </div>
                    </div>
                    <AboutProfile about={aboutData} />
                  </div>

                  <Skills />
                </div>

                <AboutMeText bio={aboutData.Bio} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
