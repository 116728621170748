import React from "react";
import Nav from "../Nav/Nav";

const Header = () => {
  return (
    <>
      <header id="header" className="fixed-top header-scrolled">
        <div className="container d-flex align-items-center justify-content-between">
          <h1 className="logo">
            <a href="index.html">Olu Adesina</a>
          </h1>
          <Nav />
        </div>
      </header>
    </>
  );
};

export default Header;
