import React from "react";
import Typewriter from "typewriter-effect";

function Hero(props) {
  const skills = props.skills.map((skill) => skill.NameOfSkill);

  return (
    <>
      <div
        id="hero"
        className="hero route bg-image"
        style={{
          backgroundImage: `url(https://api.oluadesina.com/${props.heroPageData.HeroImage})`,
        }}
      >
        <div className="overlay-itro"></div>
        <div className="hero-content display-table">
          <div className="table-cell">
            <div className="container">
              <p className="display-6 color-d">Hello, world!</p>
              <h1 className="hero-title mb-4">
                I am {props.heroPageData.FullName}
              </h1>
              <p className="hero-subtitle">
                <span
                  className="typed"
                  data-typed-items="Designer, Developer, Freelancer, Photographer"
                ></span>
              </p>
              <div
                style={{ color: "#fff", fontWeight: 600, fontSize: "2.5rem" }}
              >
                <Typewriter
                  onInit={(typewriter) => {
                    skills.map((sk) => {
                      return typewriter
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString(sk)
                        .start();
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
