import React, { useState } from "react";

const Nav = (props) => {
  const [navClass, setNavClass] = useState("navbar");
  const [x, setX] = useState("list");

  const setMobileNavClass = () => {
    if (x === "x") {
      setNavClass("navbar");
      setX("list");
      return;
    }
    setNavClass("navbar navbar-mobile");
    setX("x");
  };

  const removeNavMobileClass = () => {
    setNavClass("navbar");
    setX("list");
  };

  return (
    <>
      <nav id="navbar" className={navClass}>
        <ul>
          <li>
            <a
              onClick={removeNavMobileClass}
              className="nav-link scrollto active"
              href="#hero"
            >
              Home
            </a>
          </li>
          <li>
            <a
              onClick={removeNavMobileClass}
              className="nav-link scrollto"
              href="#about"
            >
              About
            </a>
          </li>
          <li>
            <a
              onClick={removeNavMobileClass}
              className="nav-link scrollto"
              href="#services"
            >
              Experiance
            </a>
          </li>
          <li>
            <a
              onClick={removeNavMobileClass}
              className="nav-link scrollto "
              href="#work"
            >
              Portfolio
            </a>
          </li>

          <li>
            <a
              onClick={removeNavMobileClass}
              className="nav-link scrollto"
              href="#contact"
            >
              Contact
            </a>
          </li>
        </ul>
        <i
          className={`bi mobile-nav-toggle bi-${x}`}
          onClick={setMobileNavClass}
        ></i>
      </nav>
    </>
  );
};

export default Nav;
