import React, { useContext } from "react";
import ContactForm from "./ContactForm";

import AppContext from "../../Store/AppContext";

const Contact = () => {
  const data = useContext(AppContext);
  return (
    <>
      <section
        id="contact"
        className="paralax-mf footer-paralax bg-image sect-mt4 route"
        style={{
          backgroundImage: `url(https://api.oluadesina.com/${data.HeroPageData.BackgroundImage})`,
        }}
      >
        <div className="overlay-mf"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="title-box-2">
                        <h5 className="title-left">Email Me</h5>
                      </div>
                      <div>
                        <ContactForm />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-box-2 pt-4 pt-md-0">
                        <h5 className="title-left">Get in Touch</h5>
                      </div>
                      <div className="more-info">
                        <p className="lead"></p>
                        <ul className="list-ico">
                          <li>
                            <span className="bi bi-geo-alt"></span>
                            {data.AboutPageData.Location}
                          </li>
                          <li>
                            <span className="bi bi-phone"></span>
                            {data.AboutPageData.TelePhone}
                          </li>
                          <li>
                            <span className="bi bi-envelope"></span>{" "}
                            {data.AboutPageData.Email}
                          </li>
                        </ul>
                      </div>
                      <div className="socials">
                        <ul></ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
